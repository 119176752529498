import { Typography, VStack } from "@smartrent/ui";

import { SmartRentAppStoreButtons } from "@/react/common/AppStoreButtons";

export const AlloyYaleDownload = ({ message }: { message: string }) => {
  return (
    <VStack spacing={16}>
      <Typography type="title3" color="primary">
        Access Your Home
      </Typography>
      <Typography>{message}</Typography>
      <SmartRentAppStoreButtons />
    </VStack>
  );
};
