import { MasterCredential, MasterCredentialType } from "@/react/types";

/**
 * Format access code display
 *
 * ex:
 *
 * 12345678 to 1234 4567
 * 123456 to 123 456
 * 12345 to 12 345
 */
export function formatCode(code: string) {
  // Nothing to format
  if (!code) {
    return code;
  }
  // Don't format short codes
  if (code.length <= 4) {
    return code;
  }
  // Don't format obfuscated codes (*****)
  if (code[0] === "*") {
    return code;
  }
  return `${code.substring(0, Math.floor(code.length / 2))} ${code.substring(
    Math.floor(code.length / 2)
  )}`;
}

/**
 * Calls formatCode for pin codes, otherwise is just returns the raw code.
 */
export function formatMasterCredentialCode(credential: MasterCredential) {
  if (credential.credential_type == MasterCredentialType.Code) {
    return formatCode(credential.code);
  } else {
    return credential.code;
  }
}
