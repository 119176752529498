import { useCurrentUser } from "@/react/context/mgmt-api/user";
import { Role, User } from "@/react/types";

import {
  PermissionKey,
  getPermissionsForRole,
  getSettingsForRole,
} from "@/react/common/utils/permissions";

export enum PermissionType {
  ALL,
  ANY,
}

export const usePermission = (
  permission: PermissionKey | PermissionKey[],
  permissionType?: PermissionType
) => {
  const user: User = useCurrentUser();
  return can(user, permission, permissionType);
};

/**
 * Functional function that returns a boolean when given a user, a permission type, and a resource
 */
export function can(
  user: User,
  permission: PermissionKey | PermissionKey[],
  permissionType?: PermissionType
): boolean {
  if (Array.isArray(permission)) {
    return permissionType === PermissionType.ANY
      ? permission.some((permission) => can(user, permission) === true)
      : permission.every((permission) => can(user, permission) === true);
  }

  if (user?.employee_role && user?.employee_role[permission as keyof Role]) {
    return true;
  }

  return false;
}

export const useCurrentUserHasEquivalentRole = (role: Role | null) => {
  const user: User = useCurrentUser();
  return hasEquivalentRole(user, role);
};

export function hasEquivalentRole(user: User, role: Role | null) {
  if (!user?.employee_role || !role) {
    return false;
  }

  if (user.employee_role.id === role.id) {
    return true;
  }

  if (user.employee_role.immutable) {
    return true;
  }

  const userPermissions = getPermissionsForRole(user.employee_role);
  const rolePermissions = getPermissionsForRole(role);

  const missingAnyPermissions = Object.keys(rolePermissions).some(
    (permissionKey) => !userPermissions[permissionKey]
  );

  const userRoleSettings = getSettingsForRole(user.employee_role);
  const roleSettings = getSettingsForRole(role);

  const missingAnyRoleSettings = Object.keys(roleSettings).some(
    (settingKey) => !userRoleSettings[settingKey as keyof typeof roleSettings]
  );

  return !missingAnyPermissions && !missingAnyRoleSettings;
}
