import { useCallback } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useParams, useHistory } from "react-router";

import {
  FormikSubmit,
  Typography,
  VStack,
  FormikTextInputField,
} from "@smartrent/ui";

import { useVerifyCommunityZipcodeMutation } from "@/react/queries/mgmt-api/users/password";

const verifyCommunityZipcodeValidationSchema = Yup.object({
  confirmCommunityZipcode: Yup.string().required(
    "Unit zip or postal code is required"
  ),
});

export const ZipValidationForm = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  const [verifyCommunityZipcode] = useVerifyCommunityZipcodeMutation();

  const verifyCommunityZipcodeSubmit = useCallback(
    async (values) => {
      await verifyCommunityZipcode({
        token,
        communityZipcode: values.confirmCommunityZipcode,
      });
      history.push("/login");
    },
    [verifyCommunityZipcode, token, history]
  );

  return (
    <Formik
      initialValues={{ confirmCommunityZipcode: "" }}
      validationSchema={verifyCommunityZipcodeValidationSchema}
      onSubmit={verifyCommunityZipcodeSubmit}
    >
      <VStack spacing={16}>
        <Typography type="title2">Confirm Unit Zip or Postal Code</Typography>
        <FormikTextInputField
          assistiveText="Please enter the zip code for the unit you are trying to access"
          name="confirmCommunityZipcode"
          label="Confirm Unit Zip or Postal Code"
        />
        <FormikSubmit />
      </VStack>
    </Formik>
  );
};
