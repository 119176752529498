import { StyleSheet, Image } from "react-native";
import { LegacyCard, Text, useTheme } from "@smartrent/ui";
import { Link } from "react-router-dom";

export default ({
  title,
  image,
  to,
  imageHeight = 140,
}: {
  title: string;
  image: any; // this should be ImageSourcePropType but upstream consumes of this get errors when passing in strings
  to: string;
  imageHeight: number;
}) => {
  const { colors } = useTheme();

  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <LegacyCard style={styles.card}>
        <Image source={image} style={[styles.image, { height: imageHeight }]} />
        <Text style={[styles.title, { color: colors.gray700 }]}>{title}</Text>
      </LegacyCard>
    </Link>
  );
};

const styles = StyleSheet.create({
  card: {
    width: 250,
    height: 250,
    margin: 15,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    textAlign: "center",
    fontSize: 14,
    flex: 1,
  },
  image: {
    marginTop: 20,
    marginBottom: 20,
    height: 140,
    width: "auto",
    resizeMode: "contain",
  },
});
