import React from "react";
import { View, StyleSheet } from "react-native";
import { Route, Switch } from "react-router-dom";

import { Text, useTheme } from "@smartrent/ui";

import Landing from "./screens/Landing";
import HasCode from "./screens/HasCode";
import Jam from "./screens/Jam";
import CodeError from "./screens/CodeError";
import PowerError from "./screens/PowerError";
import NoCode from "./screens/NoCode";
import GetCode from "./screens/GetCode";

export default () => {
  const { colors } = useTheme();

  return (
    <View>
      <View style={[styles.header, { backgroundColor: colors.primary }]}>
        <Text style={styles.headerTitle}>Locked Out?</Text>
        <Text style={styles.headerSubtitle}>
          If you're having trouble with your lock, following the steps below may
          resolve your issue.
        </Text>
      </View>
      <View style={styles.content}>
        <Switch>
          <Route exact path="/lockout" render={() => <Landing />} />
          <Route exact path="/lockout/has-code" render={() => <HasCode />} />
          <Route exact path="/lockout/jam" render={() => <Jam />} />
          <Route
            exact
            path="/lockout/code-error"
            render={() => <CodeError />}
          />
          <Route
            exact
            path="/lockout/power-error"
            render={() => <PowerError />}
          />
          <Route exact path="/lockout/no-code" render={() => <NoCode />} />
          <Route exact path="/lockout/get-code" render={() => <GetCode />} />
        </Switch>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    justifyContent: "center",
    alignItems: "center",
    padding: 32,
  },
  headerTitle: {
    color: "white",
    fontSize: 28,
    marginBottom: 20,
    fontWeight: "bold",
  },
  headerSubtitle: {
    color: "white",
    maxWidth: 380,
    textAlign: "center",
  },
  content: {
    maxWidth: 900,
    width: "100%",
    alignSelf: "center",
  },
});
