import { useCallback } from "react";
import {
  QueryFunctionContext,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { ListQueryResponse, PaginationParams, useToast } from "@smartrent/ui";
import { useMutationCompat, useQueryCompat } from "@smartrent/hooks";

import { instance } from "@/react/hooks/api";
import { TemperatureScale } from "@/react/common/utils";
import { getErrorMessage } from "@/react/lib/axios-helpers";
import {
  AxiosMutationConfig,
  createAxiosQuery,
} from "@/react/hooks/react-query";
import { $Device, $Unit, Id, ThirdPartyDevice } from "@/react/types";

interface ListGroupUnitsProps {
  groupId: number | string;
  params?: Record<string, unknown>;
}

interface AddUnitMutationOption {
  groupId: string;
  unit: UnitRequestBody;
}

interface EditUnitMutationOption {
  unitId: string;
  unit: UnitRequestBody;
}

export enum UnitStatus {
  model = "Model",
  vacant = "Vacant",
  down = "Down",
  occupied = "Occupied",
  common = "Common Area",
  sold = "Sold",
}

interface UnitRequestBody {
  marketing_name: string;
  tour_marketing_name: null | string;
  unit_code: string;
  remote_id?: string;
  can_edit?: boolean;
  image_url?: string;
  timezone: string;
  status: UnitStatus;
  street_address_1: string;
  street_address_2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  building?: string;
  floor?: number;
  sqft?: number;
  min_marketing_rent?: null | number;
  max_marketing_rent?: null | number;
  beds?: number;
  baths?: number;
  temperature_scale: TemperatureScale;
  enable_accessibility_icon: boolean;
}

interface ListGroupUnitsFilters extends PaginationParams {
  building?: string;
  excluded_unit_ids?: Array<string | number>;
  floor?: string;
  ids?: Array<string | number>;
  is_occupied?: string;
  marketing_name?: string;
  has_access_control_device?: string;
  has_any_device?: boolean;
  has_any_lock?: string;
  has_primary_lock?: string;
  hub_status?: string | null;
  hub_offline_before?: string;
  unit_status?: string;
  only_count?: true; // can be used to fetch just the unit count and avoid additional lookups
}

interface ListGroupVacantUnitsFilters extends PaginationParams {
  building?: string;
  marketing_name?: string;
}

const getUnitsUrl = (groupId: number | string) => `/groups/${groupId}/units`;

const getVacantUnitsUrl = (groupId: any) => `/groups/${groupId}/vacant-units`;

export const useInvalidateGroupsUnitsQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (groupId: string) => queryClient.invalidateQueries(["units", { groupId }]),
    [queryClient]
  );
};

export async function listGroupUnitsV2(
  _key: "units",
  { groupId }: ListGroupUnitsProps,
  filters: ListGroupUnitsFilters
) {
  if (groupId && groupId != "undefined") {
    const { data } = await instance().get<ListQueryResponse<$Unit>>(
      getUnitsUrl(groupId),
      { params: filters }
    );
    return data;
  }

  const nonResult: ListQueryResponse<$Unit> = {
    records: [],
    current_page: 1,
    total_pages: 1,
    total_records: 0,
  };

  return Promise.resolve(nonResult);
}

export async function listGroupUnits(
  _key: "units",
  { groupId, params }: ListGroupUnitsProps
) {
  if (groupId) {
    const { data } = await instance().get(getUnitsUrl(groupId), {
      params: { ...params },
    });

    return data;
  }

  return Promise.resolve(null);
}

export const useGroupUnitsQuery = (groupId: number, params: any = {}) =>
  useQueryCompat(["units", { groupId, params }], listGroupUnits);

export const useInvalidateGroupUnitsQuery = (
  groupId: number,
  params: any = {}
) => {
  const queryClient = useQueryClient();
  return useCallback(
    () => queryClient.invalidateQueries(["units", { groupId, params }]),
    [queryClient, groupId, params]
  );
};

export const useGroupUnitsV2Query = (
  groupId: number,
  params: ListGroupUnitsFilters = {}
) =>
  useQueryCompat<any, ListQueryResponse<$Unit>>(
    ["units", { groupId }, params],
    listGroupUnitsV2
  );

export const FetchGroupVacantUnits = async ({
  queryKey,
}: QueryFunctionContext) => {
  const [, { groupId }, filters] = queryKey as [
    string | readonly unknown[],
    Record<string, unknown>,
    ListGroupVacantUnitsFilters
  ];

  const response = await instance().get(getVacantUnitsUrl(groupId), {
    params: filters,
  });

  return {
    records: response.data.records || 0,
    current_page: filters.page ? Number(filters.page) : 1,
    total_pages: response.data.total_pages || 1,
    total_records: response.data.total_records || 1,
  };
};

export const useInvalidateUnitQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (unitId: Id) => queryClient.invalidateQueries(["unit", `${unitId}`]),
    [queryClient]
  );
};

export const useGetUnitQuery = createAxiosQuery(
  "unit",
  async (unitId: number | string) => {
    if (!unitId) {
      return Promise.resolve(null);
    }

    const { data } = await instance().get(`/units/${unitId}`);

    return data;
  }
);

export const useGetUnitQueryWithParams = createAxiosQuery(
  "unit",
  async ({
    unitId,
    params,
  }: {
    unitId?: number | string | null;
    params?: { ap_mode_status?: boolean };
  }) => {
    if (!unitId) return null;
    const { data } = await instance().get(`/units/${unitId}`, {
      params,
    });
    return data;
  }
);

export const useAddUnitMutation = (
  options?: AxiosMutationConfig<AddUnitMutationOption>
) => {
  const setToast = useToast();
  const queryClient = useQueryClient();

  return useMutationCompat(async ({ unit, groupId }: AddUnitMutationOption) => {
    try {
      const response = await instance().post("units", {
        group_id: groupId,
        ...unit,
      });

      setToast({
        status: "success",
        title: "Successfully Created Unit",
        message: "The unit was successfully created.",
      });

      queryClient.invalidateQueries(["units", { groupId }]);

      return response.data;
    } catch (err: any) {
      setToast({
        status: "error",
        title: "Unable to Add Unit",
        message: getErrorMessage(err),
      });
      throw err;
    }
  }, options);
};

export const useEditUnit = (
  options?: AxiosMutationConfig<EditUnitMutationOption>
) => {
  const setToast = useToast();
  const queryClient = useQueryClient();

  return useMutationCompat(async ({ unit, unitId }: EditUnitMutationOption) => {
    try {
      const adjustedMinMarketingRent = unit.min_marketing_rent
        ? unit.min_marketing_rent * 100
        : null;
      const adjustedMaxMarketingRent = unit.max_marketing_rent
        ? unit.max_marketing_rent * 100
        : null;
      const response = await instance().patch(`units/${unitId}`, {
        ...unit,
        min_marketing_rent: adjustedMinMarketingRent,
        max_marketing_rent: adjustedMaxMarketingRent,
      });

      setToast({
        status: "success",
        title: "Successfully Updated Unit",
        message: "Unit was successfully updated.",
      });

      queryClient.invalidateQueries(["unit", unitId]);

      return response;
    } catch (err: any) {
      setToast({
        status: "error",
        title: "Unable to Update Unit",
        message: getErrorMessage(err),
      });

      throw err;
    }
  }, options);
};

export const useDeleteUnit = (options?: AxiosMutationConfig<string>) => {
  const setToast = useToast();

  return useMutationCompat(async (unitId) => {
    try {
      const response = await instance().delete(`units/${unitId}`);

      setToast({
        status: "success",
        title: "Successfully Deleted Unit",
        message: "The unit was successfully deleted.",
      });

      return response;
    } catch (err: any) {
      setToast({
        status: "error",
        title: "Unable to Delete Unit",
        message: getErrorMessage(err),
      });
      throw err;
    }
  }, options);
};

export interface UnitOverviewResponse {
  complete_construction_bg_process_status: string;
  unit: {
    id: number;
    marketing_name: string;
    status: string;
    raw_status: string | null;
    is_occupied: boolean;
    group_id: number;
    unit_access_enabled: boolean;
    construction_mode_enabled: boolean;
  };
  hub: {
    id: number;
    online: boolean;
  };
  devices: Pick<
    $Device,
    | "id"
    | "battery_level"
    | "battery_powered"
    | "internal_name"
    | "name"
    | "online"
    | "type"
    | "attributes"
    | "room_id"
    | "primary_lock"
  >[];
  third_party_devices: Pick<
    ThirdPartyDevice,
    | "id"
    | "internal_name"
    | "unit_id"
    | "remote_id"
    | "device_type"
    | "remote_data"
    | "third_party_provider_configuration_id"
    | "attributes"
  >[];
}

interface UnitOverviewParams {
  unitId?: string | number;
}

export const useUnitOverview = ({ unitId }: UnitOverviewParams) => {
  return useQuery(
    ["unit-overview", { unitId: `${unitId}` }],
    async () => {
      const { data } = await instance().get<UnitOverviewResponse>(
        `/units/${unitId}/overview`
      );
      return data;
    },
    {
      enabled: !!unitId,
    }
  );
};
