export enum Locales {
  EN_US = "en-US",
  EN_CA = "en-CA",
}

export enum Currencies {
  USD = "USD",
  CAD = "CAD",
}

export const formatMoney = (
  amount: number = 0,
  locale: Locales = Locales.EN_US,
  currency: Currencies = Currencies.USD
): string => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(amount);
};
