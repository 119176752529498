import React from "react";

import OptionCard from "../components/OptionCard";
import LockoutStep from "../components/LockoutStep";

export default () => {
  return (
    <LockoutStep
      title="Do you have a code?"
      subtitle="Select One"
      options={[
        <OptionCard
          key={1}
          title="I have a code"
          image="/images/lockout/illustration--phone-with-code.svg"
          to="/lockout/has-code"
          imageHeight={140}
        />,
        <OptionCard
          key={2}
          title="I don't have a code"
          image="/images/lockout/illustration--phone-code-unknown.svg"
          to="/lockout/no-code"
          imageHeight={140}
        />,
      ]}
      showBackButton={false}
    />
  );
};
