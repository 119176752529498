import React from "react";

import { Typography } from "@smartrent/ui";

import { UserRole } from "@/react/types/users/user";

import { Section } from "./Section";
import { InstructionStep } from "./InstructionStep";
interface AlloySaltoLockInstructionsProps {
  userRole: UserRole;
}

export function AlloySaltoLockInstructions({
  userRole,
}: AlloySaltoLockInstructionsProps) {
  return (
    <Section title="Using Your Home Lock">
      <Typography type="body">
        Follow the steps below to use your lock
      </Typography>

      <InstructionStep
        stepNumber={1}
        title={`Open the ${
          userRole === UserRole.HomeOwner ? "Alloy Home" : "SmartRent"
        } App`}
        imageUrl="/images/alloy-salto/resident-lock-instructions.svg"
        imageTitle="Press the unlock icon and hold your phone within 2 inches of the lock."
        instruction="Press the unlock icon and hold your phone within 2 inches of the lock."
      />
    </Section>
  );
}
