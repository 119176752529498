import { StyleSheet } from "react-native";
import { RouteComponentProps } from "react-router-dom";
import { useMedia } from "react-use";

import { Box } from "@smartrent/ui";

import { UserRole } from "@/react/types/users/user";

import { ResetForm } from "./ResetForm";
import { ZipValidationForm } from "./ZipValidationForm";

interface SetPasswordProps extends RouteComponentProps<{ token: string }> {
  email?: string | null | undefined;
  role: UserRole;
  set_password: boolean;
  verify_zip: boolean;
  errors?: { field: string; description: string }[] | null | undefined;
}

const SetPassword = ({
  email,
  role,
  set_password,
  verify_zip,
  match,
}: SetPasswordProps) => {
  const isDesktop = useMedia("(min-width: 768px)");
  return (
    <Box style={isDesktop ? styles.desktop : styles.mobile}>
      {/* We have two booleans to control what gets rendered here which creates
      4 different combos though we only actually want 3:

      1. verify_zip && set_password - Render ResetForm
      2. !verify_zip && set_password - Render ResetForm
      3. verify_zip && !set_password - Render ZipValidationForm

      It doesn't make sense to have !verify_zip and !set_password but it is possible.
      If we see that, we want to render  the ResetForm as a fallback. Thus, we only
      have one state where we want ZipValidationForm so we explicitly look for
      that and render ResetForm in all other cases.*/}
      {verify_zip && !set_password ? (
        <ZipValidationForm />
      ) : (
        <ResetForm
          email={email}
          role={role}
          match={match}
          verifyZip={verify_zip}
        />
      )}
    </Box>
  );
};

export const SetPasswordNoBox = ({ email, role, match }: SetPasswordProps) => {
  return <ResetForm email={email} role={role} match={match} />;
};

export default SetPassword;

const styles = StyleSheet.create({
  desktop: {
    maxWidth: 500,
  },
  mobile: {
    width: "100%",
  },
});
