import { $Unit } from "./Unit";
import { $Group } from "./Group";
import { Workflow } from "./workflows/Workflow";
import { MaintenanceTeamMember } from "./MaintenanceTeamMember";

interface User {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  mobile_phone: string | null;
  remote_id: string | null;
  saml_id: string | null;
  uuid: string;
}

export interface WorkOrder {
  assigned_to_id: number | null;
  assigned_to_name: string;
  automated_type: string | null;
  can_request_fob_property_access: boolean;
  comments: string | null;
  completed_at: string;
  completed_by_id: number | null;
  completed_by_type: string | null;
  community_access_code: number | null;
  created_at: string;
  created_by: Workflow | null;
  created_by_id: string | null;
  days_open: number | null;
  device_attribute_value: string | null;
  device_id: number | null;
  entry_notes: string | null;
  extended_at: string | null;
  false_alarm: boolean | null;
  group: $Group;
  id: number;
  inserted_at: string;
  issue_location: string;
  maintenance_team_member: MaintenanceTeamMember | null;
  make_ready: boolean;
  marketing_name: string;
  metadata: WorkOrderMetadata;
  permission_to_enter: string;
  priority: string;
  relative_created_at: string;
  remote_id: number | null;
  requested_by: string | null;
  resident_email: string | null;
  resident_phone: string | null;
  resolution: string;
  scheduled_date: string | null;
  scheduled_end_time: string | null;
  scheduled_start_time: string | null;
  service_problem: string;
  status: string;
  status_reason: string | null;
  units: $Unit[];
  user: User | null;
  vendor_id: number | null;
  vendor_name: string;
  work_performed: string | null;
}

export interface WorkOrderUnitActionMetadata {
  id: number;
  name: string | undefined;
  business_hours: string;
  actions: string[];
}

// See Typespec work_order_metadata
export interface WorkOrderMetadata {
  can_request_fob_unit_access: boolean;
  completed_by: string | null;
  created_by: User | null;
  has_locks_supporting_access_codes: boolean;
  requires_resolution: boolean;
  resolution_options: string[];
  units: WorkOrderUnitActionMetadata[];
  show_alloy_pass: boolean;
  community_access_code: number | null;
}

export enum WorkOrderAutomatedTypeEnum {
  Leak = "leak",
  Temp = "temp",
  Humidity = "humidity",
}
