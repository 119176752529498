import React, { createContext, useContext, useState } from "react";

import { ExpiringLinkBundleProps } from "./types";

export interface ExpiringLinkContextProps extends ExpiringLinkBundleProps {
  updateProps: React.Dispatch<React.SetStateAction<ExpiringLinkBundleProps>>;
}

const ExpiringLinkContext = createContext<ExpiringLinkContextProps>({
  status: "expired",
  updateProps: () => {
    // noop
  },
});

export const ExpiringLinkContextProvider: React.FC<ExpiringLinkBundleProps> = ({
  children,
  ...initialProps
}) => {
  const [value, setValue] = useState<ExpiringLinkBundleProps>(initialProps);

  return (
    <ExpiringLinkContext.Provider value={{ ...value, updateProps: setValue }}>
      {children}
    </ExpiringLinkContext.Provider>
  );
};

export const useExpiringLinkContext = () => useContext(ExpiringLinkContext);
