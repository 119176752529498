import { Link, Typography, VStack } from "@smartrent/ui";

import { Section } from "./Section";

interface ELPResidentWifiExperiencesProps {
  wifiSSID: string;
  wifiPassword: string;
}

export function ELPResidentWifiExperiences({
  wifiSSID = "",
  wifiPassword = "",
}: ELPResidentWifiExperiencesProps) {
  return (
    <Section title="Resident WiFi">
      <VStack spacing={16}>
        <Typography type="body">
          Please join the network below using your SmartRent email address and
          password provided below. To reset your password or update wifi network
          settings, use the wifi portal. Got questions? Visit our knowledge base
          here:{" "}
          <Link href="https://smartrent.com/support/" target="_blank">
            [Link]
          </Link>
        </Typography>

        <Typography type="bodySemibold">Your Network Name:</Typography>
        <Typography type="bodyLargeSemibold" color="primary">
          {wifiSSID}
        </Typography>
        <Typography type="bodySemibold">Your Network's Password:</Typography>
        <Typography type="bodyLargeSemibold" color="primary">
          {wifiPassword}
        </Typography>
      </VStack>
    </Section>
  );
}
