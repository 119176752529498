import { Typography, VStack } from "@smartrent/ui";

import { ResidentAppAppStoreButtons } from "@/react/common/AppStoreButtons";

export const AlloySaltoDownload = ({ message }: { message: string }) => {
  return (
    <VStack spacing={16}>
      <Typography type="title3" color="primary">
        Access Your Home
      </Typography>
      <Typography>{message}</Typography>
      <ResidentAppAppStoreButtons />
    </VStack>
  );
};
