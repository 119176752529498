import React from "react";
import { QuestionCircleBorder } from "@smartrent/icons";

import TroubleshootingStep from "../components/TroubleshootingStep";
import LockoutStep from "../components/LockoutStep";

export default () => {
  return (
    <LockoutStep
      warningMessage="If the lock screen is blank or you're unable to tap the checkmark, your lock may be experiencing a power issue."
      showBackButton={true}
      steps={[
        <TroubleshootingStep
          key={1}
          iconText="Try This"
          title="Place your entire palm over the face of the lock."
          image="/images/lockout/illustration--palm-over-lock.svg"
        />,
        <TroubleshootingStep
          key={2}
          iconText="Try This"
          title="If the lock battery is dead, use a 9V battery to re-power the lock. Then enter your code."
          image="/images/lockout/illustration--lock-jump-start.svg"
        />,
        <TroubleshootingStep
          key={3}
          iconText="Try This"
          title="If the checkmark disappears after entering your code, place your palm over the lock."
          image="/images/lockout/illustration--enter-code-hand-on-lock.svg"
        />,
        <TroubleshootingStep
          key={4}
          iconComponent={<QuestionCircleBorder size={36} color={"white"} />}
          title="If you are still experiencing problems, please contact your property's maintenance team for further assistance."
        />,
      ]}
    />
  );
};
