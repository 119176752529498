import { View, StyleSheet } from "react-native";

import {
  Box,
  FormikPasswordInputField,
  FormikSubmit,
  FormikTextInputField,
  Typography,
  VStack,
} from "@smartrent/ui";

import { useFocus } from "@/react/hooks/useFocus";

const EmailForm = () => {
  const focusRef = useFocus();

  return (
    <>
      <Box>
        <VStack spacing={16}>
          <Typography style={styles.typography} type="title3">
            Sign in to your Account
          </Typography>
          <FormikTextInputField
            name="email"
            label="Email"
            submitOnEnter
            required
            ref={focusRef}
            textInputProps={{
              nativeID: "email",
            }}
            testID="email"
          />
          <FormikSubmit
            label="Continue"
            submittingLabel="Submitting..."
            size="small"
          />
        </VStack>
      </Box>
      {/* This might be required to trigger the password managers */}
      <View pointerEvents="none" style={styles.passwordContainer}>
        <FormikPasswordInputField name="password" label="Password" required />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  typography: { textAlign: "center" },
  passwordContainer: {
    position: "absolute",
    opacity: 0,
  },
});

export default EmailForm;
