import React from "react";
import { View } from "react-native";

import { SmartRentDownload } from "./SmartRentDownload";
import { AlloyPassDownload } from "./AlloyPassDownload";
import { Section } from "./Section";

export function AlloyAccessInstructions({ isGuest }: { isGuest: boolean }) {
  return (
    <Section title="Community Doors">
      {!isGuest ? (
        <SmartRentDownload />
      ) : (
        <AlloyPassDownload
          message={
            "Please download our companion app, Alloy Pass, in order to access this property."
          }
        />
      )}

      {!isGuest && (
        <View>
          <img
            alt={"lock"}
            src={"/images/alloy-access/property-lock-instructions.svg"}
          />
        </View>
      )}
    </Section>
  );
}
