import { StyleSheet, View } from "react-native";

import { VStack } from "@smartrent/ui";

import { LockInstructions } from "./LockInstructions";
import { ExpiringLinkFooter } from "./ExpiringLinkFooter";
import { AlloyAccessInstructions } from "./AlloyAccessInstructions";
import { AlloySaltoLockInstructions } from "./AlloySaltoLockInstructions";
import { AlloySchlageLockInstructions } from "./AlloySchlageLockInstructions";
import { ExpiringLinkHeader } from "./ExpiringLinkHeader";
import { useExpiringLinkContext } from "./ExpiringLinkContext";
import { ELPResidentWifiExperiences } from "./ELPResidentWifiExperience";

const ExpiringLink = () => {
  const {
    showLockInstructions,
    showAlloySalto,
    showAlloySchlage,
    showELPResidentWifiExperience,
    wifiSSID,
    wifiPassword,
    isGuest,
    buildingAccessCodes,
    buildingAccessType,
    imageUrlConfig,
    userRole,
    status,
  } = useExpiringLinkContext();

  const showAlloyAccessBle =
    buildingAccessCodes !== null && buildingAccessType == "ble";

  return (
    <View style={styles.root}>
      <View style={styles.contentContainer}>
        <VStack spacing={16}>
          <ExpiringLinkHeader />

          {showLockInstructions ? (
            <LockInstructions
              wakeLockUrl={imageUrlConfig?.wake_lock_image_url}
              inputCodeUrl={imageUrlConfig?.input_code_image_url}
              submitCodeUrl={imageUrlConfig?.submit_code_image_url}
              lockingUrl={imageUrlConfig?.locking_image_url}
              userRole={userRole}
            />
          ) : null}

          {showAlloyAccessBle ? (
            <AlloyAccessInstructions isGuest={isGuest} />
          ) : null}

          {showAlloySalto ? (
            <AlloySaltoLockInstructions userRole={userRole} />
          ) : null}

          {showAlloySchlage ? (
            <AlloySchlageLockInstructions userRole={userRole} />
          ) : null}

          {showELPResidentWifiExperience ? (
            <ELPResidentWifiExperiences
              wifiSSID={wifiSSID}
              wifiPassword={wifiPassword}
            />
          ) : null}

          <ExpiringLinkFooter status={status} userRole={userRole} />
        </VStack>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    maxWidth: "95vw",
  },
  contentContainer: {
    maxWidth: 650,
    marginHorizontal: "auto",
  },
});

export default ExpiringLink;
