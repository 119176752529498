import React from "react";

import OptionCard from "../components/OptionCard";
import LockoutStep from "../components/LockoutStep";

export default () => {
  return (
    <LockoutStep
      title="Which illustration best describes what your lock is currently doing?"
      subtitle="Select One"
      options={[
        <OptionCard
          key={1}
          title="Are only odd numbers flashing?"
          image="/images/lockout/illustration--x-pattern.svg"
          to="/lockout/jam"
          imageHeight={110}
        />,
        <OptionCard
          key={2}
          title="Do the numbers on the lock flash from side to side?"
          image="/images/lockout/illustration--numbers-flashing.svg"
          to="/lockout/code-error"
          imageHeight={110}
        />,
        <OptionCard
          key={3}
          title="Is the lock screen blank or is there no option to select a checkmark after entering a code?"
          image="/images/lockout/illustration--blank-screen.svg"
          to="/lockout/power-error"
          imageHeight={110}
        />,
      ]}
      showBackButton={true}
    />
  );
};
