import { Text, useTheme } from "@smartrent/ui";
import { View, StyleSheet, Image } from "react-native";
import Media from "react-media";

interface Props {
  iconText?: string;
  iconComponent?: React.ReactNode;
  title: string;
  image?: string;
  content?: React.ReactNode;
}

export default ({
  iconText = null,
  iconComponent = null,
  title,
  image = null,
  content = null,
}: Props) => {
  const { colors } = useTheme();

  return (
    <Media query="(min-width: 768px)">
      {(matches) =>
        matches ? (
          <View style={styles.container}>
            <View style={styles.iconContainer}>
              <View style={[styles.icon, { backgroundColor: colors.primary }]}>
                {iconComponent}
                {iconText && <Text style={styles.iconText}>{iconText}</Text>}
              </View>
            </View>
            <View style={styles.content}>
              <Text>{title}</Text>
              <View style={styles.imageContainer}>
                {image && (
                  <Image source={{ uri: image }} style={styles.image} />
                )}
                {content}
              </View>
            </View>
          </View>
        ) : (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={styles.container}>
              <View style={styles.iconContainer}>
                <View
                  style={[styles.icon, { backgroundColor: colors.primary }]}
                >
                  {iconComponent}
                  {iconText && <Text style={styles.iconText}>{iconText}</Text>}
                </View>
              </View>
              <View style={styles.content}>
                <Text>{title}</Text>
              </View>
            </View>
            <View style={[styles.imageContainer, { width: "100%" }]}>
              {image && <Image source={{ uri: image }} style={styles.image} />}
              {content}
            </View>
          </View>
        )
      }
    </Media>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginTop: 40,
  },
  content: {
    marginTop: 15,
    flex: 1,
  },
  icon: {
    borderRadius: 100,
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
  },
  iconText: {
    color: "white",
    fontSize: 14,
    textAlign: "center",
    lineHeight: 18,
  },
  iconContainer: {
    minWidth: 100,
    alignItems: "center",
  },
  image: {
    height: 300,
    width: "auto",
    resizeMode: "contain",
  },
  imageContainer: {
    marginTop: 40,
    marginBottom: 40,
  },
});
