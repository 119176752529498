import React from "react";
import { ThemeProvider, themes } from "@smartrent/ui";

interface RoleBasedThemeProviderProps {
  role: string;
  children?: React.ReactNode;
}

export default function RoleBasedThemeProvider({
  role,
  children,
}: RoleBasedThemeProviderProps) {
  return (
    <ThemeProvider
      initialMode="light"
      theme={role === "home_owner" ? themes["alloy"] : themes["smartrent"]}
    >
      {children}
    </ThemeProvider>
  );
}
