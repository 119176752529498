import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { View, StyleSheet } from "react-native";
import axios from "axios";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { ActivityIndicator, Box, StatusBanner } from "@smartrent/ui";

import helpers from "@/react/helpers";

import EmailForm from "./EmailForm";
import EmailPasswordForm from "./EmailPasswordForm";
import TfaForm from "./TfaForm";

interface LoginValues {
  password: string;
  email: string;
  tfa: string;
}

const Login = () => {
  // New: A user can land on this form and be directly prompted for a 2FA code
  const location = useLocation();
  const qs = helpers.qsFromLocation(location);

  const [isLoading, setIsLoading] = useState(false);
  const [tfaToken, setTfaToken] = useState(qs.tfa_api_token || null);
  const [showEmailForm, setShowEmailForm] = useState(!qs.tfa_api_token);
  const [showEmailPasswordForm, setShowEmailPasswordForm] = useState(false);
  const [showTfaForm, setShowTfaForm] = useState(!!qs.tfa_api_token);
  const [error, setError] = useState<string | null>(null);

  const handleEmailSubmit = (email: string) => {
    setIsLoading(true);
    setError(null);

    axios
      .post("/mgmt-api/v1/sessions/check-saml", {
        email,
      })
      .then((response) => {
        if (response.data.redirect_url) {
          window.location.href = response.data.redirect_url;
        } else {
          setShowEmailForm(false);
          setShowEmailPasswordForm(true);
          setShowTfaForm(false);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setError("Something went wrong, please try again");
        setIsLoading(false);
      });
  };

  const handleEmailPasswordSubmit = (email: string, password: string) => {
    setIsLoading(true);
    setError(null);

    axios
      .post("/authentication/sessions", {
        email,
        password,
      })
      .then((response) => {
        let redirectTo = "/";
        if (response.data.redirect_to_on_login) {
          redirectTo = response.data.redirect_to_on_login;
        }

        if (response.data.tfa_api_token) {
          setTfaToken(response.data.tfa_api_token);
          setShowEmailForm(false);
          setShowEmailPasswordForm(false);
          setError(null);
          setShowTfaForm(true);
          setIsLoading(false);
        } else {
          window.location.href = redirectTo;
        }
      })
      .catch((e) => {
        setIsLoading(false);
        const error =
          e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "invalid_credentials";
        switch (error) {
          case "organization_inactive":
            setError(
              "Organization no longer active. If this is a mistake, please contact support."
            );
            break;
          case "account_locked":
            setError("Too many attempts to login, please try again later.");
            break;
          default:
            setError("Invalid email or password");
            break;
        }
      });
  };

  const handleTfaSubmit = useCallback(
    (tfa: string) => {
      setIsLoading(true);
      setError(null);

      axios
        .post("/authentication/sessions/tfa", {
          tfa_api_token: tfaToken,
          token: tfa,
        })
        .then((response) => {
          let redirectTo = "/";
          if (response.data.redirect_to_on_login) {
            redirectTo = response.data.redirect_to_on_login;
          }
          window.location.href = redirectTo;
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.response.data.error);
        });
    },
    [tfaToken]
  );

  const loginInitialValues = {
    password: "",
    email: "",
    tfa: "",
  };

  const loginValidationSchema = qs.tfa_api_token
    ? Yup.object({
        tfa: Yup.string(),
      })
    : Yup.object({
        password: Yup.string().test(
          "onPasswordForm",
          "Enter a password",
          (value) => {
            return showEmailPasswordForm && !value ? false : true;
          }
        ),
        email: Yup.string()
          .email("Email must be a valid email")
          .required("Email is a required field"),
        tfa: Yup.string(),
      });

  const onSubmit = (
    values: LoginValues,
    actions: FormikHelpers<LoginValues>
  ) => {
    if (showEmailForm) {
      handleEmailSubmit(values.email);
    } else if (showEmailPasswordForm) {
      handleEmailPasswordSubmit(values.email, values.password);
    } else if (showTfaForm) {
      handleTfaSubmit(values.tfa);
    }
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={loginInitialValues}
      validationSchema={loginValidationSchema}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}
    >
      <View style={styles.loginContainer}>
        {error ? (
          <StatusBanner
            style={styles.statusBanner}
            title={error}
            status="error"
          />
        ) : null}
        {isLoading ? (
          <Box style={styles.activityIndicatorWrapper}>
            <ActivityIndicator size={64} />
          </Box>
        ) : (
          <>
            {showEmailForm ? <EmailForm /> : null}
            {showEmailPasswordForm ? <EmailPasswordForm /> : null}
            {showTfaForm ? <TfaForm /> : null}
          </>
        )}
      </View>
    </Formik>
  );
};

const styles = StyleSheet.create({
  loginContainer: {
    justifyContent: "center",
    marginHorizontal: "auto",
    minWidth: 280,
    maxWidth: 320,
  },
  statusBanner: { marginBottom: 16 },
  activityIndicatorWrapper: { alignItems: "center" },
});

export default Login;
