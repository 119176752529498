import { FormikSubmit, FormikTextInputField, VStack } from "@smartrent/ui";
import { Formik } from "formik";
import * as Yup from "yup";
import { StyleSheet, View } from "react-native";

import { useValidateExpiringLinkZipMutation } from "@/react/queries/public/access-codes";

import { useExpiringLinkContext } from "./ExpiringLinkContext";

const validationSchema = Yup.object({
  zip: Yup.string().required("Unit zip or postal code is required"),
});

export const ZipValidationForm = () => {
  const { updateProps, apiToken } = useExpiringLinkContext();
  const [validateZip] = useValidateExpiringLinkZipMutation();
  return (
    <View style={styles.container}>
      <Formik
        initialValues={{ zip: "" }}
        validationSchema={validationSchema}
        onSubmit={async ({ zip }) => {
          const expiringLinkProps = await validateZip({ token: apiToken, zip });
          updateProps(expiringLinkProps);
        }}
      >
        <VStack spacing={16}>
          <FormikTextInputField
            assistiveText="Please enter the zip code for the unit you are trying to access"
            name="zip"
            label="Confirm Unit Zip or Postal Code"
            required
            submitOnEnter
          />
          <FormikSubmit />
        </VStack>
      </Formik>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // Override "textAlign: center" from a component further up the render tree
    textAlign: "left",
  },
});
