import { useRef, useEffect } from "react";

/**
 * Focus an element on mount.
 *
 * Returns a ref that should be passed to the
 * component that you want to be focused.
 */
export const useFocus = () => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, []);
  return ref;
};
