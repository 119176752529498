import { StyleSheet, View } from "react-native";

import { Link, Typography, VStack } from "@smartrent/ui";

import { UserRole } from "@/react/types/users/user";

import { Section } from "./Section";
import { ExpiringLinkBundleProps } from "./types";

type ExpiringLinkFooterProps = Pick<
  ExpiringLinkBundleProps,
  "status" | "userRole"
>;

export const ExpiringLinkFooter = ({
  status,
  userRole,
}: ExpiringLinkFooterProps) => {
  return (
    <Section contentContainerStyle={styles.root}>
      <VStack spacing={16}>
        {status === "active" ? (
          <Typography type="title3" font="semibold">
            Welcome to your new smart home!
          </Typography>
        ) : null}

        <View>
          <Typography type="body">
            SmartRent.com, Inc. &copy; All Rights Reserved.
          </Typography>
          <Link
            rel="noopener noreferrer"
            target="_blank"
            href={
              userRole === UserRole.HomeOwner
                ? "https://alloysmarthome.com/privacy/"
                : "https://smartrent.com/privacy"
            }
          >
            Privacy Policy
          </Link>
        </View>
      </VStack>
    </Section>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 16,
  },
});
