import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from "react";

import { ConfirmDialog, ConfirmDialogProps } from "@smartrent/ui";

export type DialogOptions = Pick<
  ConfirmDialogProps,
  "title" | "description" | "confirmText" | "confirmType"
> & {
  cancelText?: string;
};

type State = DialogOptions & Pick<ConfirmDialogProps, "visible">;

export interface DialogContext {
  confirm: (opts: DialogOptions) => Promise<boolean>;
}

const DialogContext = createContext<DialogContext>({
  confirm: () =>
    Promise.reject(new Error("component not wrapped in DialogProvider")),
});

const defaultState: State = {
  visible: false,
  title: "",
  description: "",
  confirmType: "primary",
  confirmText: "",
  cancelText: "Cancel",
};

export const useDialog = () => useContext(DialogContext);

export const DialogProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<State>(defaultState);

  const resolveRef = useRef<any>();

  const handleConfirm = useCallback(() => {
    resolveRef.current && resolveRef.current(true);
    setState({ ...state, visible: false });
  }, [state]);

  const handleCancel = useCallback(() => {
    resolveRef.current && resolveRef.current(false);
    setState({ ...state, visible: false });
  }, [state]);

  const confirm = useCallback((opts: DialogOptions) => {
    return new Promise<boolean>((resolve) => {
      resolveRef.current = resolve;
      // Reset state here instead of in handleConfirm/handleCancel. If we reset
      // state there instead, we get a brief flash of the modal's text disappearing
      // and the button changing color as the modal transitions out.
      setState({ ...defaultState, ...opts, visible: true });
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const layoutElement = window.document.querySelector(
      "section.app__content__page"
    ) as HTMLDivElement;

    layoutElement?.style.setProperty(
      "overflow-y",
      state.visible ? "hidden" : ""
    );
    window.document.body.style.setProperty(
      "overflow-y",
      state.visible ? "hidden" : ""
    );
  }, [state.visible]);

  return (
    <DialogContext.Provider value={{ confirm }}>
      {children}
      <ConfirmDialog
        visible={state.visible}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={state.title}
        confirmText={state.confirmText}
        confirmType={state.confirmType}
        cancelText={state.cancelText}
        description={state.description}
      />
    </DialogContext.Provider>
  );
};
