import React from "react";
import { View, StyleProp, StyleSheet, ViewStyle } from "react-native";
import { useTheme } from "@smartrent/ui";

interface PageBodyProps {
  style?: StyleProp<ViewStyle>;
}

export const PageBody: React.FC<PageBodyProps> = ({ children, style }) => {
  const { colors } = useTheme();

  return (
    <View
      style={[styles.container, { backgroundColor: colors.background }, style]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    flexGrow: 1,
    alignItems: "center",
    paddingHorizontal: 32,
  },
});
