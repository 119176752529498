import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { Link } from "@smartrent/ui";

export const AlloyPassAppStoreButtons: React.FC<
  Pick<AppStoreButtonsProps, "style">
> = (props) => {
  return (
    <AppStoreButtons
      ios="https://apps.apple.com/tt/app/alloy-pass/id1519360323"
      android="https://play.google.com/store/apps/details?id=com.alloyhome.pass"
      {...props}
    />
  );
};

export const SmartRentAppStoreButtons: React.FC<
  Pick<AppStoreButtonsProps, "style">
> = (props) => {
  return (
    <AppStoreButtons
      ios="https://apps.apple.com/us/app/smartrent/id1280670682"
      android="https://play.google.com/store/apps/details?id=com.smartrent.resident"
      {...props}
    />
  );
};

export const ResidentAppAppStoreButtons: React.FC<
  Pick<AppStoreButtonsProps, "style">
> = (props) => {
  return (
    <AppStoreButtons
      ios="https://apps.apple.com/us/app/smartrent/id1280670682"
      android="https://play.google.com/store/apps/details?id=com.smartrent.resident"
      {...props}
    />
  );
};

interface AppStoreButtonsProps {
  ios: string;
  android: string;
  style?: StyleProp<ViewStyle>;
}

export const AppStoreButtons: React.VFC<AppStoreButtonsProps> = ({
  ios,
  android,
  style,
}) => {
  return (
    <View style={[styles.root, style]}>
      <Link
        href={ios}
        target="_blank"
        rel="noreferrer noopener"
        style={styles.item}
      >
        <img
          style={{ maxHeight: 50 }}
          src="/images/ios_store.png"
          alt="Download on the App Store"
        />
      </Link>
      <View style={styles.spacer} />
      <Link
        href={android}
        target="_blank"
        rel="noreferrer noopener"
        style={styles.item}
      >
        <img
          style={{ maxHeight: 50 }}
          src="/images/google_store.png"
          alt="Get it on Google Play"
        />
      </Link>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    flexGrow: 0,
    flexShrink: 0,
  },
  spacer: {
    flexGrow: 0,
    flexShrink: 0,
    width: 16,
  },
});
