import React, { useEffect } from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { useMedia } from "react-use";

import { ActivityIndicator, Spacer } from "@smartrent/ui";

import {
  CheckPermissionWithRedirect,
  CheckPermissionWithRedirectProps,
} from "@/react/common/components/CheckPermissionsWithRedirect";
import { useDocumentTitle } from "@/react/hooks/document-title";

import { SIDEBAR_WIDTH } from "./Sidebar";
import { useZendeskChat } from "./zendesk/ZendeskChatContext";

export const RESTRUCTURE_BREAKPOINT = "(max-width: 768px)";
export interface ReactPageProps {
  zendeskChat?: boolean;
  topBar?: React.ReactNode;
  hideSidebar?: boolean;
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  childrenContainerStyle?: StyleProp<ViewStyle>;
  loading?: boolean;
  title?: string;
}

export const ReactPage = ({
  topBar,
  children,
  style,
  hideSidebar,
  contentContainerStyle,
  childrenContainerStyle,
  loading = false,
  zendeskChat = true,
  title,

  // permissions related
  permission,
  permissionType,
  redirectUrl,
}: ReactPageProps & CheckPermissionWithRedirectProps) => {
  const { setShouldShow } = useZendeskChat();
  useDocumentTitle(title);
  const isMobile = useMedia(RESTRUCTURE_BREAKPOINT);
  const sideBarOffset = !isMobile && !hideSidebar ? SIDEBAR_WIDTH : 0;

  useEffect(() => {
    setShouldShow(zendeskChat);
  }, [zendeskChat, setShouldShow]);

  return (
    <View style={[styles.container, style]}>
      <View
        style={[
          styles.pageSection,
          { marginLeft: sideBarOffset },
          contentContainerStyle,
        ]}
      >
        {topBar}
        <CheckPermissionWithRedirect
          permission={permission}
          permissionType={permissionType}
          redirectUrl={redirectUrl}
        >
          {loading ? (
            <View style={styles.centered}>
              <ActivityIndicator size={64} />
            </View>
          ) : (
            <View style={[styles.childrenContainer, childrenContainerStyle]}>
              {children}
            </View>
          )}
        </CheckPermissionWithRedirect>
        {zendeskChat ? <Spacer height={64} /> : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: "100%",
  },
  pageSection: {
    flexGrow: 1,
    minHeight: "100%",
  },
  childrenContainer: {
    padding: 32,
  },
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
