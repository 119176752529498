import { Typography } from "@smartrent/ui";

import { UserRole } from "@/react/types/users/user";

import { InstructionStep } from "./InstructionStep";
import { Section } from "./Section";

interface LockInstructionsProps {
  wakeLockUrl: string;
  inputCodeUrl: string;
  submitCodeUrl: string;
  lockingUrl: string;
  userRole: string;
}

export function LockInstructions({
  wakeLockUrl,
  inputCodeUrl,
  submitCodeUrl,
  lockingUrl = "",
  userRole,
}: LockInstructionsProps) {
  const lockYourLock =
    lockingUrl ||
    ["yrd216", "yrd210", "yrdntb645"].some((m) => lockingUrl?.includes(m))
      ? "Press any button on the keypad to lock your door."
      : "To lock your door when leaving, simply cover the face of the keypad again.";

  return (
    <Section title="Unlocking the Door">
      <Typography>
        Follow the easy, step-by-step process below to let yourself in.
      </Typography>
      <Typography style={{ marginBottom: "60px" }}>
        If you have any issues accessing the unit, please contact the leasing
        office.
      </Typography>

      {wakeLockUrl ? (
        <InstructionStep
          stepNumber={1}
          title="Wake your lock"
          imageUrl={wakeLockUrl}
          imageTitle="Wake the lock"
          instruction="To wake up your lock, press the Yale Logo, or cover the face of the lock."
        />
      ) : null}

      <InstructionStep
        stepNumber={wakeLockUrl ? 2 : 1}
        title="Enter your code"
        imageUrl={inputCodeUrl}
        imageTitle="Enter the access code"
        instruction="Entering your code and pressing the check mark will unlock your lock."
      />

      <InstructionStep
        stepNumber={wakeLockUrl ? 3 : 2}
        title={
          <>
            Press{" "}
            <img
              src={
                userRole === UserRole.HomeOwner
                  ? "/images/email/Alloy-checkmark.svg"
                  : "/images/email/check-button.jpg"
              }
              style={{ paddingLeft: 8 }}
              alt="Check button on lock"
            />
          </>
        }
        imageUrl={submitCodeUrl}
        imageTitle="Press the confirmation key"
      />

      {lockingUrl ? (
        <InstructionStep
          title="Locking Your Door"
          imageUrl={lockingUrl}
          imageTitle="Locking your door"
          instruction={lockYourLock}
        />
      ) : null}
    </Section>
  );
}
