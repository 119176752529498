import { useCallback, useState } from "react";
import { StyleSheet } from "react-native";

import { Button } from "@smartrent/ui";

import { useRegenerateAccessCodeMutation } from "@/react/queries/public/access-codes";

import { useExpiringLinkContext } from "./ExpiringLinkContext";

export const RegenerateExpiringLinkButton = () => {
  const { apiToken } = useExpiringLinkContext();
  const [regenerateAccessCodeMutation] = useRegenerateAccessCodeMutation();
  const [codeRegenerated, setCodeRegenerated] = useState(false);

  const handleRegeneration = useCallback(async () => {
    setCodeRegenerated(true);

    regenerateAccessCodeMutation(apiToken).catch(() =>
      setCodeRegenerated(false)
    );
  }, [apiToken, regenerateAccessCodeMutation]);

  return (
    <Button
      disabled={codeRegenerated}
      onPress={handleRegeneration}
      style={styles.root}
      variation="default"
    >
      Send Me a New Link
    </Button>
  );
};

const styles = StyleSheet.create({
  root: {
    marginTop: 30,
  },
});
