import React from "react";
import { QuestionCircleBorder } from "@smartrent/icons";

import TroubleshootingStep from "../components/TroubleshootingStep";
import LockoutStep from "../components/LockoutStep";

export default () => {
  return (
    <LockoutStep
      warningMessage="There may have been an issue delivering your access code."
      showBackButton={true}
      steps={[
        <TroubleshootingStep
          key={1}
          iconText="Try This"
          title="Check your inbox for an email from SmartRent containing your access code."
          image="/images/lockout/illustration--hand-email.svg"
        />,
        <TroubleshootingStep
          key={2}
          iconText="Try This"
          title="Check your texts for a message containing your code or an access link."
          image="/images/lockout/illustration--hand-text.svg"
        />,
        <TroubleshootingStep
          key={3}
          iconComponent={<QuestionCircleBorder size={36} color={"white"} />}
          title="If you have not received an access code, please contact your leasing office."
        />,
      ]}
    />
  );
};
