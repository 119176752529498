import React from "react";
import { QuestionCircleBorder } from "@smartrent/icons";
import { Text, useTheme } from "@smartrent/ui";
import { Image, View, StyleSheet } from "react-native";

import TroubleshootingStep from "../components/TroubleshootingStep";
import LockoutStep from "../components/LockoutStep";

export default () => {
  const { colors } = useTheme();

  return (
    <LockoutStep
      warningMessage="The numbers flashing from side to side is an indication that the code you have entered is invalid."
      showBackButton={true}
      steps={[
        <TroubleshootingStep
          key={1}
          iconText="Try This"
          title="Confirm you're using the correct code as listed in your SmartRent app. This code should have also been sent to you via text and email."
          content={
            <View style={styles.contentContainer}>
              <View style={styles.textContainer}>
                <Text style={styles.textTitle}>Hello, John</Text>
                <Text
                  style={{
                    color: colors.primary,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  Your access code is
                </Text>
                <Text style={styles.code}>1234</Text>
              </View>
              <Image
                source={{
                  uri: "/images/lockout/illustration--hand-phone-access-code.svg",
                }}
                style={{ width: 300, height: 300, resizeMode: "contain" }}
              />
            </View>
          }
        />,
        <TroubleshootingStep
          key={2}
          iconText="Try This"
          title="Make sure to tap the checkmark after you enter your code."
          image="/images/lockout/illustration--enter-code-press-check.svg"
        />,
        <TroubleshootingStep
          key={3}
          iconComponent={<QuestionCircleBorder size={36} color={"white"} />}
          title="If your problem persists, please contact our support team."
          content={
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <View style={styles.contactType}>
                <Image
                  source={{ uri: "/images/lockout/phone-icon.svg" }}
                  style={styles.contactIcon}
                />
                <Text style={[styles.contactTitle, { color: colors.primary }]}>
                  PHONE
                </Text>
                <Text style={styles.contactText}>
                  Call us toll free 24/7 with questions or concerns.
                </Text>
                <View style={{ flexDirection: "row" }}>
                  <Text>Toll free: </Text>
                  <a
                    href="tel:1-844-479-7929"
                    style={{ color: colors.primary, textDecoration: "none" }}
                  >
                    +1 (844) 479-7929
                  </a>
                </View>
              </View>
              <View style={styles.contactType}>
                <Image
                  source={{ uri: "/images/lockout/email-icon.svg" }}
                  style={styles.contactIcon}
                />
                <Text style={[styles.contactTitle, { color: colors.primary }]}>
                  EMAIL
                </Text>
                <Text style={styles.contactText}>
                  Our support staff is here to help you through email.
                </Text>
                <a
                  href="mailto:info@smartrent.com"
                  style={{ color: colors.primary }}
                >
                  info@smartrent.com
                </a>
              </View>
            </View>
          }
        />,
      ]}
    />
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  textContainer: {
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 50,
    marginRight: 40,
  },
  textTitle: {
    fontWeight: "bold",
    fontSize: 24,
    marginBottom: 5,
  },
  code: {
    fontSize: 46,
    fontWeight: "bold",
    marginTop: 20,
  },
  contactType: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 50,
    marginRight: 50,
  },
  contactIcon: {
    height: 60,
    width: 60,
    resizeMode: "contain",
  },
  contactTitle: {
    fontWeight: "bold",
    fontSize: 18,
    marginTop: 20,
    marginBottom: 20,
  },
  contactText: {
    maxWidth: 200,
    textAlign: "center",
    marginBottom: 40,
  },
});
