import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";

import { Box, Typography, useTheme } from "@smartrent/ui";

interface SectionProps {
  title?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
}

export const Section: React.FC<SectionProps> = ({
  title,
  style,
  contentContainerStyle,
  children,
}) => {
  const { colors } = useTheme();

  return (
    <Box style={[styles.root, style]}>
      {React.isValidElement(title) ? (
        title
      ) : title ? (
        <View style={[styles.header, { backgroundColor: colors.primary }]}>
          <Typography type="title3" font="semibold" color="textPrimaryInverse">
            {title}
          </Typography>
        </View>
      ) : null}
      <View style={[styles.contentContainer, contentContainerStyle]}>
        {children}
      </View>
    </Box>
  );
};

const styles = StyleSheet.create({
  root: {
    padding: 0,
    borderRadius: 4,
  },
  header: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",

    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,

    paddingVertical: 20,
    marginBottom: 20,
  },
  contentContainer: {
    paddingHorizontal: 16,
    paddingVertical: 40,
    textAlign: "center",
  },
});
