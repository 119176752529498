import { useCallback } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { match, useHistory } from "react-router";
import {
  CheckboxLabel,
  FormikCheckbox,
  FormikSubmit,
  Typography,
  VStack,
  Link,
  FormikTextInputField,
} from "@smartrent/ui";

import * as links from "@/lib/links";
import {
  useUpdateUserPasswordMutation,
  useForgotPasswordMutation,
  useCreatePasswordMutation,
} from "@/react/queries/mgmt-api/users/password";
import { useCurrentUserQuery } from "@/react/queries/mgmt-api/users/current-user";
import { UserRole } from "@/react/types/users/user";
import { useIsMobile } from "@/react/hooks/breakpoints";

import { PasswordFields } from "./PasswordFields";

type FormFields = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

interface ResetFormProps {
  email: string;
  role: UserRole;
  match: match<{
    token: string;
  }>;
  verifyZip?: boolean;
}

const updatePasswordValidationSchema = Yup.object({
  currentPassword: Yup.string().required("Please enter your current password"),
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "Needs a minimum of 8 characters")
    .matches(/[a-z]/, "Needs at least one lowercase letter")
    .matches(/[A-Z]/, "Needs at least one uppercase letter")
    .matches(/\W|_/g, "Needs at least one special character")
    .matches(/[0-9]/, "Needs at least one number"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});

const forgotPasswordValidationSchema = Yup.object({
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "Needs a minimum of 8 characters")
    .matches(/[a-z]/, "Needs at least one lowercase letter")
    .matches(/[A-Z]/, "Needs at least one uppercase letter")
    .matches(/\W|_/g, "Needs at least one special character")
    .matches(/[0-9]/, "Needs at least one number"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});

const createPasswordValidationSchema = Yup.object({
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "Needs a minimum of 8 characters")
    .matches(/[a-z]/, "Needs at least one lowercase letter")
    .matches(/[A-Z]/, "Needs at least one uppercase letter")
    .matches(/\W|_/g, "Needs at least one special character")
    .matches(/[0-9]/, "Needs at least one number"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  confirmCommunityZipcode: Yup.string().when("verifyZip", {
    is: true,
    then: Yup.string().required("Unit zip or postal code is required"),
  }),
  terms: Yup.boolean()
    .required()
    .oneOf([true], "You must accept Terms and Conditions"),
});

export const ResetForm = ({
  email,
  role,
  match,
  verifyZip = false,
}: ResetFormProps) => {
  const isMobile = useIsMobile();
  const userQuery = useCurrentUserQuery();
  const history = useHistory();
  const region = window.location.hostname.includes("intl") ? "intl" : "us";
  // We're using MIX_ENV instead of NODE_ENV because we need to differentiate
  // between QA and PROD but NODE_ENV is the same for both of those environments.
  const deepLinkUrl =
    process.env.MIX_ENV === "prod"
      ? `https://app-links.smartrent.com/links/?link=https://app-links.smartrent.com/invite/${region}/${match.params.token}&apn=com.smartrent.resident&isi=1280670682&ibi=com.smartrent.resident`
      : `https://app-links.smartrent-qa.com/links/?link=https://app-links.smartrent-qa.com/invite/${region}/${match.params.token}&apn=com.smartrent.resident.qa&isi=1485917751&ibi=com.smartrent.resident.qa`;

  if (isMobile && role === UserRole.Consumer) {
    window.location.href = encodeURI(deepLinkUrl);
  }

  const [updateUserPassword] = useUpdateUserPasswordMutation();
  const [changeUserPassword] = useForgotPasswordMutation();
  const [createPassword] = useCreatePasswordMutation();

  const changeUserPasswordSubmit = useCallback(
    async (values) => {
      await changeUserPassword({
        token: match.params.token,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      });
    },
    [changeUserPassword, match.params.token]
  );

  const createPasswordSubmit = useCallback(
    async (values) => {
      await createPassword({
        token: match.params.token,
        confirmPassword: values.confirmPassword,
        newPassword: values.newPassword,
        communityZipcode: values.confirmCommunityZipcode,
        terms: values.terms,
      });
      history.push("/login");
    },
    [createPassword, match.params.token, history]
  );

  const updateUserPasswordSubmit = useCallback(
    async (values) => {
      const userId = userQuery.data.id;
      await updateUserPassword({
        userId,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
      });
    },
    [updateUserPassword, userQuery]
  );

  if (match.path.includes("forgot-password")) {
    return (
      <Formik
        initialValues={{
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={forgotPasswordValidationSchema}
        onSubmit={changeUserPasswordSubmit}
      >
        <VStack spacing={8}>
          <Typography type="title2">Create a new password</Typography>
          <PasswordFields email={email} match={match} />
          <FormikSubmit />
        </VStack>
      </Formik>
    );
  } else if (match.path.includes("invite")) {
    return (
      <Formik
        initialValues={{
          newPassword: "",
          confirmPassword: "",
          confirmCommunityZipcode: "",
          terms: false,
          verifyZip,
        }}
        validationSchema={createPasswordValidationSchema}
        onSubmit={createPasswordSubmit}
      >
        <VStack spacing={16}>
          <Typography type="title2">Create a new password</Typography>
          <PasswordFields email={email} match={match} />
          {verifyZip ? (
            <FormikTextInputField
              assistiveText="Please enter the zip code for the unit you are trying to access"
              name="confirmCommunityZipcode"
              label="Confirm Unit Zip or Postal Code"
            />
          ) : null}
          <FormikCheckbox
            name="terms"
            label={
              <CheckboxLabel>
                <Typography>You acknowledge you agree to our </Typography>
                <Link href={links.unauthenticated.terms()} target="_blank">
                  Terms
                </Link>{" "}
                <Typography>and have read our </Typography>
                <Link
                  href={
                    role === UserRole.HomeOwner
                      ? "https://alloysmarthome.com/privacy/"
                      : "https://smartrent.com/privacy"
                  }
                  target="_blank"
                >
                  Privacy Policy
                </Link>{" "}
                <Typography>including our use of cookies.</Typography>
              </CheckboxLabel>
            }
          />
          <Typography type="caption" color="helperText">
            Once you finish creating your account, we recommend that you setup
            2-Factor Authentication within your profile settings.
          </Typography>
          <FormikSubmit />
        </VStack>
      </Formik>
    );
  } else {
    return (
      <Formik<FormFields>
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={updatePasswordValidationSchema}
        onSubmit={updateUserPasswordSubmit}
      >
        <VStack spacing={16}>
          <Typography type="title2">Create a new password</Typography>
          <PasswordFields email={email} match={match} />
          <FormikSubmit />
        </VStack>
      </Formik>
    );
  }
};
