import { StyleSheet } from "react-native";
import { Typography, useTheme, Link } from "@smartrent/ui";

import { NavLinkColors, NavLinkProps } from "@/react/types";

const getTextColor = (color: NavLinkColors, isActive: any) => {
  if (color === "light") {
    return "textPrimaryInverse";
  }
  return isActive ? "primary" : "textSecondary";
};

export const NavLink = ({
  title,
  isActive = false,
  type = "sidebar",
  style,
  route,
  color = "default",
}: NavLinkProps) => {
  const { colors } = useTheme();

  return (
    <Link
      style={[
        styles.container,
        type === "sidebar" && styles.sidebarContainer,
        type === "topNav" && styles.topNavContainer,
        isActive && type === "sidebar" && styles.sidebarActive,
        isActive && type === "topNav" && styles.topNavActive,
        { borderColor: color === "default" ? colors.primary : colors.white },
      ]}
      to={route}
    >
      <Typography
        type="title5"
        color={getTextColor(color, isActive)}
        style={[style, type === "topNav" ? styles.fontSize14 : null]}
      >
        {title}
      </Typography>
    </Link>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
  sidebarContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    display: "flex",
    width: "100%",
    height: 32,
    marginVertical: 4,
    paddingRight: 24,
  },
  topNavContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 80,
    paddingHorizontal: 16,
  },
  fontSize14: {
    fontSize: 14,
  },
  sidebarActive: {
    borderRightWidth: 4,
    paddingRight: 20,
  },
  topNavActive: {
    borderBottomWidth: 4,
    height: 76,
    marginTop: 4,
  },
});
