import { View, TouchableOpacity, StyleSheet } from "react-native";

import { Typography, TextType } from "@smartrent/ui";
import { ArrowLeft } from "@smartrent/icons";

export interface BackButtonProps {
  title: string;
  onPress: () => void;
  type?: TextType;
  isSubheader?: boolean;
}

export const BackButton = ({
  title,
  onPress,
  type,
  isSubheader,
}: BackButtonProps) => {
  return (
    <TouchableOpacity
      style={[
        styles.backContainer,
        isSubheader === true ? styles.subheader : styles.centeredHeight,
      ]}
      onPress={onPress}
    >
      <View style={styles.arrow}>
        <ArrowLeft color="black" size={15} />
      </View>
      <Typography type={type || "title4"}>{title}</Typography>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  backContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  subheader: {
    marginBottom: 8,
  },
  centeredHeight: {
    height: 80,
  },
  arrow: {
    marginRight: 16,
  },
});
