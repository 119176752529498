import { StyleSheet } from "react-native";
import { match } from "react-router-dom";
import { FormikPasswordInputField, Typography } from "@smartrent/ui";

interface PasswordFieldsProps {
  email: string;
  match: match<{
    token: string;
  }>;
}

export const PasswordFields = ({ email, match }: PasswordFieldsProps) => {
  return (
    <>
      {email ? <Typography type="title6">Email</Typography> : null}
      <Typography style={styles.email}>{email}</Typography>
      {match.path.includes("change-password") ? (
        <FormikPasswordInputField
          submitOnEnter
          name="currentPassword"
          label="Current Password"
        />
      ) : null}
      <FormikPasswordInputField name="newPassword" label="New Password" />
      <FormikPasswordInputField
        submitOnEnter
        name="confirmPassword"
        label="Confirm Password"
      />
    </>
  );
};

const styles = StyleSheet.create({
  email: { marginBottom: 8 },
});
