import { Typography, VStack } from "@smartrent/ui";

import { SmartRentAppStoreButtons } from "@/react/common/AppStoreButtons";

export const SmartRentDownload = () => {
  return (
    <VStack spacing={16}>
      <Typography type="title3" color="primary">
        Access Your Property
      </Typography>
      <Typography>
        To access locks throughout the community, please download the SmartRent
        App. Click the links below and login to get started.
      </Typography>
      <SmartRentAppStoreButtons />
    </VStack>
  );
};
