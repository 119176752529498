import React from "react";
import { View, StyleSheet, TouchableWithoutFeedback } from "react-native";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import Media from "react-media";

import { Text, useTheme } from "@smartrent/ui";
import { QuestionCircleBorder, ArrowLeft } from "@smartrent/icons";

interface LockoutStepProps extends RouteComponentProps {
  title?: string;
  subtitle?: string;
  options?: any[];
  showBackButton?: boolean;
  warningMessage?: string;
  steps?: any[];
}

const LockoutStep = ({
  title,
  subtitle,
  options,
  showBackButton,
  steps,
  warningMessage,
  history,
}: LockoutStepProps) => {
  const { colors } = useTheme();

  return (
    <View style={styles.contentContainer}>
      {showBackButton && (
        <TouchableWithoutFeedback onPress={history.goBack}>
          <View style={styles.backButton}>
            <ArrowLeft size={16} color={colors.primary} />
            <Text style={[styles.backButtonText, { color: colors.primary }]}>
              Back
            </Text>
          </View>
        </TouchableWithoutFeedback>
      )}
      {warningMessage && (
        <View
          style={[
            styles.messageBox,
            { borderColor: colors.gray200, backgroundColor: colors.gray050 },
          ]}
        >
          <View style={{ paddingRight: 15 }}>
            <QuestionCircleBorder size={20} color={colors.primary} />
          </View>
          <View style={{ width: "100%", flex: 1 }}>
            <Text style={[styles.messageBoxTitle, { color: colors.primary }]}>
              What's happening?
            </Text>
            <Text style={{ flex: 1 }}>{warningMessage}</Text>
          </View>
        </View>
      )}
      {title && (
        <View>
          <Media query="(min-width: 768px)">
            {(matches) => <View style={{ height: matches ? 100 : 0 }} />}
          </Media>
          <Text style={[styles.title, { color: colors.primary }]}>{title}</Text>
        </View>
      )}
      {subtitle && (
        <Text style={[styles.subtitle, { color: colors.gray600 }]}>
          {subtitle}
        </Text>
      )}
      {options && options.length && (
        <View style={styles.options}>{options}</View>
      )}
      <Link
        to={"/lockout/get-code"}
        style={{ textDecoration: "none", marginTop: 40 }}
      >
        <Text style={[styles.skip, { color: colors.primary }]}>
          Skip this and send me a code
        </Text>
      </Link>
      {steps && steps.length && (
        <View style={{ width: "100%", maxWidth: 800, marginBottom: 100 }}>
          <View
            style={[styles.stepHeader, { borderBottomColor: colors.gray200 }]}
          >
            <Text style={styles.stepHeaderText}>Try the techniques below</Text>
          </View>
          <View>{steps}</View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  subtitle: {
    marginBottom: 30,
    fontStyle: "italic",
  },
  options: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  skip: {
    fontSize: 14,
    textDecorationLine: "underline",
    cursor: "pointer",
  },
  messageBox: {
    padding: 20,
    borderRadius: 8,
    borderWidth: 2,
    flexDirection: "row",
    width: "100%",
  },
  messageBoxTitle: {
    fontWeight: "bold",
    fontSize: 20,
    marginBottom: 10,
  },
  stepHeader: {
    justifyContent: "center",
    borderBottomWidth: 1,
    padding: 20,
    alignItems: "center",
    marginTop: 40,
  },
  stepHeaderText: {
    fontWeight: "bold",
    fontSize: 20,
  },
  backButton: {
    flexDirection: "row",
    alignSelf: "flex-start",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 20,
    cursor: "pointer",
  },
  backButtonText: {
    marginLeft: 10,
    fontSize: 14,
  },
});

export default withRouter(LockoutStep);
