import React, { useState } from "react";
import axios from "axios";
import { View, StyleSheet } from "react-native";
import Media from "react-media";
import * as yup from "yup";

import {
  Text,
  useTheme,
  Typography,
  TextInputField,
  VStack,
  Button,
  Link,
  Box,
} from "@smartrent/ui";

const inputEmailValidation = yup.string().email().required();
const inputPhoneValidation = yup
  .string()
  .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);

export default () => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { colors } = useTheme();

  const axiosInstance = axios.create();

  const sendCode = async () => {
    setShowConfirmation(false);
    setError(null);

    const validEmail = await inputEmailValidation.isValid(emailOrPhone);
    const validPhone = await inputPhoneValidation.isValid(emailOrPhone);

    if (!validEmail && !validPhone) {
      setError("Invalid email or phone number");
    } else {
      await axiosInstance.post("/lockout", {
        user_input: validPhone
          ? emailOrPhone.replace(/[^\d]/g, "")
          : emailOrPhone,
      });
      setShowConfirmation(true);
    }
  };

  return (
    <View style={styles.contentContainer}>
      <Media query="(min-width: 768px)">
        {(matches) => <View style={{ height: matches ? 100 : 0 }} />}
      </Media>
      <VStack flexChildren spacing={16}>
        <Typography color="primary" type="title3" style={styles.backup}>
          Get a Backup Code
        </Typography>
        <Typography style={[styles.inputMessage, { color: colors.helperText }]}>
          The email or phone number you provide must match what we have on file.
        </Typography>
        <TextInputField
          label="Email or Phone Number"
          required
          textInputProps={{
            value: emailOrPhone,
            onChangeText: setEmailOrPhone,
          }}
        />
        <Button onPress={sendCode}>Get Code</Button>
      </VStack>
      {showConfirmation && (
        <View style={styles.alertContainer}>
          <Box style={{ backgroundColor: colors.success }}>
            <Text style={{ color: colors.white }}>
              If the email address or phone number provided is associated with a
              SmartRent resident account, they will receive an access link with
              a backup code.
            </Text>
          </Box>
        </View>
      )}
      {error && (
        <View style={styles.alertContainer}>
          <Box style={{ backgroundColor: colors.error }}>
            <Text style={{ color: colors.white }}>{error}</Text>
          </Box>
        </View>
      )}

      <View style={styles.bottomTextContainer}>
        <Text>
          <Text style={styles.bottomText}>
            If you're still having trouble getting a code, please{" "}
          </Text>
          <Link href="mailto:info@smartrent.com"> contact support.</Link>
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    maxWidth: 400,
    alignSelf: "center",
  },
  backup: { textAlign: "center" },
  inputMessage: {
    textAlign: "center",
    fontStyle: "italic",
    marginBottom: 8,
  },
  bottomTextContainer: {
    marginTop: 80,
    flexDirection: "row",
  },
  bottomText: {
    fontStyle: "italic",
  },
  alertContainer: {
    marginTop: 30,
  },
});
