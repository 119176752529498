import React from "react";
import { QuestionCircleBorder } from "@smartrent/icons";

import TroubleshootingStep from "../components/TroubleshootingStep";
import LockoutStep from "../components/LockoutStep";

export default () => {
  return (
    <LockoutStep
      warningMessage="If the odd numbers on the keypad are flashing, the lock may be jammed."
      showBackButton={true}
      steps={[
        <TroubleshootingStep
          key={1}
          iconText="Try This"
          title="While pulling the door toward you, enter the code."
          image="/images/lockout/illustration-hand-pulling.svg"
        />,
        <TroubleshootingStep
          key={2}
          iconText="Try This"
          title="While pushing on the door, enter the code."
          image="/images/lockout/illustration-hand-pushing.svg"
        />,
        <TroubleshootingStep
          key={3}
          iconText="Try This"
          title="Apply pressure in multiple directions to free up room for the deadbolt."
          image="/images/lockout/illustration--apply-pressure.svg"
        />,
        <TroubleshootingStep
          key={4}
          iconComponent={<QuestionCircleBorder size={36} color={"white"} />}
          title="If none of the above steps worked, your lock is likely jammed. Please contact your property's maintenance team for further assistance."
        />,
      ]}
    />
  );
};
