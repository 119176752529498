import React from "react";
import { View, StyleSheet } from "react-native";

import { useTheme } from "@smartrent/ui";

interface GenericTopBarProps {
  backAction?: React.ReactNode;
  pageTitle?: React.ReactNode;
  actions?: React.ReactNode;
  fullWidth?: boolean; // Set to true if the page doesn't have a side bar.
}

export const GenericTopBar = ({
  pageTitle,
  actions,
  backAction,
}: GenericTopBarProps) => {
  const { colors } = useTheme();

  return (
    <View
      style={[
        styles.header,
        {
          borderBottomColor: colors.border,
          backgroundColor: colors.background,
        },
      ]}
    >
      {backAction}
      {pageTitle}
      {actions}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    right: 0,
    zIndex: 2,
    flexDirection: "row",
    justifyContent: "space-between",
    height: 80,
    alignItems: "center",
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    fontWeight: 600,
  },
});
