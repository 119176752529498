import { useState } from "react";
import { StyleSheet } from "react-native";
import {
  Box,
  CheckboxLabel,
  Typography,
  Link,
  FormActions,
  VStack,
  Button,
  Checkbox,
} from "@smartrent/ui";

import * as links from "../../../lib/links";

export default function TermsOfService({ csrf }: any) {
  const [checked, setChecked] = useState(false);

  return (
    <form id="tos_form" method="POST" action={links.resident.acceptUserTerms()}>
      <Box style={styles.box}>
        <VStack spacing={16}>
          <Typography style={styles.typography} type="title2">
            Welcome to SmartRent!
          </Typography>
          <input type="hidden" name="_csrf_token" value={csrf} />
          {/* RNW doesn't render an actual input element, so we have to do shenanigans. */}
          <input
            type="hidden"
            name="tos_checkbox"
            checked={checked}
            value={checked ? "on" : "off"}
          />
          <Checkbox
            name="tos_checkbox_display"
            checked={checked}
            onChangeChecked={(v) => setChecked(v === "mixed" ? false : v)}
            label={
              <CheckboxLabel>
                You acknowledge you agree to our{" "}
                <Link
                  href={links.unauthenticated.terms()}
                  rel="noopener noreferrer"
                  target="_blank"
                  style={styles.link}
                >
                  Terms
                </Link>{" "}
                and have read our{" "}
                <Link
                  href="https://smartrent.com/privacy/"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={styles.link}
                >
                  Privacy Policy
                </Link>{" "}
                including our use of Cookies.
              </CheckboxLabel>
            }
          />
          <FormActions>
            <Button
              disabled={!checked}
              onPress={() => {
                (
                  document.getElementById("tos_form") as HTMLFormElement
                ).submit();
              }}
            >
              Continue
            </Button>
          </FormActions>
        </VStack>
      </Box>
    </form>
  );
}

const styles = StyleSheet.create({
  box: {
    maxWidth: 400,
    paddingHorizontal: 16,
    paddingVertical: 32,
  },
  typography: { marginHorizontal: "auto" },
  link: { fontWeight: "600", fontSize: "inherit" as any },
});
