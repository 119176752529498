import axios from "axios";

import { getErrorMessage } from "@/react/lib/axios-helpers";

import { createAxiosMutation } from "@/react/hooks/react-query";
import {
  ExpiringLinkBundleProps,
  ExpiringLinkStatus,
  ImageUrlConfig,
} from "@/react/bundles/expiring_links/types";

export const instance = () => {
  const axiosInstance = axios.create({
    baseURL: "/p",
  });

  return axiosInstance;
};

export const useRegenerateAccessCodeMutation = createAxiosMutation(
  async (token: string) => {
    const { data } = await instance().post(`/access-codes/${token}/regenerate`);
    return data;
  },
  {
    successToast: () => ({
      message:
        "Please check your email or text messages for an updated link for this access code.",
    }),
    errorToast: (err) => {
      const message = getErrorMessage(err);
      return {
        message,
      };
    },
  }
);

interface ValidateZipResponse {
  code: string;
  codes_devices: {
    accessCode: string;
    deviceName: string;
  }[];
  first_name: string;
  api_token_jti: string;
  building_access_codes: string[];
  building_access_type: string;
  show_lock_instructions: boolean;
  status: ExpiringLinkStatus;
  marketing_name: string;
  property_name: string;
  show_alloy_pass: boolean;
  show_alloy_salto: boolean;
  show_alloy_schlage: boolean;
  show_alloy_yale: boolean;
  show_alloy_parking: boolean;
  show_elp_resident_wifi_experience: boolean;
  wifi_ssid: string;
  wifi_password: string;
  user_role: string;
  image_url_config: ImageUrlConfig;
  is_missing_vault_code: boolean;
}

export const useValidateExpiringLinkZipMutation =
  createAxiosMutation<ExpiringLinkBundleProps>(
    async (args: any) => {
      const { data } = (await instance().post<ValidateZipResponse>(
        `/access-codes/${args.token}/validate-zip`,
        { zip: args.zip }
      )) as { data: any };
      return {
        apiToken: data.api_token_jti,
        accessCode: data.code,
        buildingAccessCodes: data.building_access_codes,
        buildingAccessType: data.building_access_type,
        codesDevices: data.codes_devices,
        firstName: data.first_name,
        imageUrlConfig: data.image_url_config,
        marketingName: data.marketing_name,
        propertyName: data.property_name,
        showAlloyPass: data.show_alloy_pass,
        showAlloySalto: data.show_alloy_salto,
        showAlloySchlage: data.show_alloy_schlage,
        showAlloyYale: data.show_alloy_yale,
        showAlloyParking: data.show_alloy_parking,
        showLockInstructions: data.show_lock_instructions,
        showELPResidentWifiExperience: data.show_elp_resident_wifi_experience,
        wifiSSID: data.wifi_ssid,
        wifiPassword: data.wifi_password,
        isMissingVaultCode: !!data.is_missing_vault_code,
        status: data.status,
      };
    },
    {
      errorToast: (err) => {
        const message = getErrorMessage(err);
        return {
          message,
        };
      },
    }
  );
