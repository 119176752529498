import { get } from "lodash-es";

import { Permission, PermissionGroup, Role, RoleSettings } from "@/react/types";

import accessCodePermissions from "./permissions/access-code-permissions";
import communityDefaultPermissions from "./permissions/community-default-permissions";
import devicePermissions from "./permissions/device-permissions";
import hubPermissions from "./permissions/hub-permissions";
import integrationPermissions from "./permissions/integration-permissions";
import leasePermissions from "./permissions/lease-permissions";
import notificationPermissions from "./permissions/notification-permissions";
import organizationPermissions from "./permissions/organization-permissions";
import organizationReportPermissions from "./permissions/organization-report-permissions";
import parkingPermissions from "./permissions/parking-permissions";
import scopeOfWorkPermissions from "./permissions/scope-of-work-permissions";
import propertyPermissions from "./permissions/property-permissions";
import prospectPermissions from "./permissions/prospect-permissions";
import reportPermissions from "./permissions/report-permissions";
import residentPermissions from "./permissions/resident-permissions";
import rolePermissions from "./permissions/role-permissions";
import supportPermissions from "./permissions/support-permissions";
import tourPermissions from "./permissions/tour-permissions";
import unitPermissions from "./permissions/unit-permissions";
import userPermissions from "./permissions/user-permissions";
import vendorPermissions from "./permissions/vendor-permissions";
import workOrderPermissions from "./permissions/work-order-permissions";

export const permissionGroups: Record<string, PermissionGroup> = [
  accessCodePermissions,
  communityDefaultPermissions,
  devicePermissions,
  hubPermissions,
  integrationPermissions,
  leasePermissions,
  notificationPermissions,
  organizationPermissions,
  organizationReportPermissions,
  parkingPermissions,
  scopeOfWorkPermissions,
  propertyPermissions,
  prospectPermissions,
  reportPermissions,
  residentPermissions,
  rolePermissions,
  supportPermissions,
  tourPermissions,
  unitPermissions,
  userPermissions,
  vendorPermissions,
  workOrderPermissions,
].reduce((acc, group) => {
  acc[group.key] = group;
  return acc;
}, {} as Record<string, PermissionGroup>);

type PermissionGroupType = typeof permissionGroups;
export type PermissionGroupName = keyof PermissionGroupType;

type PermissionName<G extends PermissionGroupName> =
  keyof PermissionGroupType[G]["permissions"];

type Permissions = {
  [G in PermissionGroupName]: {
    [P in PermissionName<G>]: PermissionGroupType[G]["permissions"][P]["key"];
  };
};

const permissionsList: Permissions = {} as Permissions;
for (const group in permissionGroups) {
  const typedGroup = group as PermissionGroupName;
  permissionsList[group] = Object.keys(
    permissionGroups[group].permissions
  ).reduce((acc, key) => {
    const permissionKey = permissionGroups[group].permissions[key].key;
    acc[key as PermissionName<typeof typedGroup>] =
      permissionKey as (typeof permissionGroups)[typeof typedGroup]["permissions"][PermissionName<
        typeof typedGroup
      >]["key"];
    return acc;
  }, {} as Record<PermissionName<typeof typedGroup>, (typeof permissionGroups)[typeof typedGroup]["permissions"][PermissionName<typeof typedGroup>]["key"]>);
}

export const permissions: Permissions = permissionsList;

export type PermissionKey = keyof typeof permissions;

// permissions.properties.[...permissions], permissions.residents.[...permissions] -> flatten into "permissions" list
export const permissionKeys = Object.keys(permissions).reduce((acc, group) => {
  const groupPermissions = permissions[group as PermissionGroupName];
  return [...acc, ...Object.values(groupPermissions)];
}, [] as PermissionKey[]);

/**
 * Returns a list of permissions indexed by the actual
 * database key (e.g. "resident_permission_view")
 * and the boolean value of whether or not the given
 * role has that permission enabled or not.
 *
 * Example:
 * {access_code_permission_view: true, ...}
 */
export const getPermissionsForRole = (
  role: Role | null
): Record<PermissionKey, boolean> => {
  return Object.keys(permissionGroups).reduce((acc, permissionGroupKey) => {
    const group = permissionGroups[permissionGroupKey as PermissionGroupName];
    const permissionsFromRole = Object.values(group.permissions).reduce(
      (acc, permission) => {
        acc[permission.key] = role
          ? (!!role[permission.key as keyof Role] as boolean) || false
          : permission?.defaultValue ?? false;
        return acc;
      },
      {} as Record<PermissionKey, boolean>
    );

    return { ...acc, ...permissionsFromRole };
  }, {} as Record<PermissionKey, boolean>);
};

/**
 * Returns a list of role settings indexed by the actual
 * database key (e.g. "setting_assign_all_properties")
 * and the boolean value of whether or not the given
 * role has that setting enabled or not.
 *
 * Example:
 * {setting_assign_all_properties: true, ...}
 */
export const getSettingsForRole = (
  role: Role | null
): Record<RoleSettings, boolean> => {
  return Object.keys(RoleSettings).reduce((acc, roleSettingKey) => {
    const roleSetting =
      RoleSettings[roleSettingKey as keyof typeof RoleSettings];
    acc[roleSetting] = role
      ? (!!role[roleSetting as keyof Role] as boolean) || false
      : false;
    return acc;
  }, {} as Record<RoleSettings, boolean>);
};

export const getPermissionDotNotatedPath = (
  group: PermissionGroup,
  permission: string
) => `${group.key}.${permission}`;

// "residents.view" -> "resident_permission_view"
export const getPermissionKeyFromDotNotatedPath = (
  dependencyPermissionKey: string
): string => get(permissions, dependencyPermissionKey, "").toString();

export const getPermissionKey = (
  group: PermissionGroup,
  permission: string
): string =>
  getPermissionKeyFromDotNotatedPath(
    getPermissionDotNotatedPath(group, permission)
  );

// "resident_permission_view" -> t<Permission[...]>
export const getPermissionFromPermissionKey = (
  permissionKey: string
): Permission | undefined => {
  // -- Merge all permission groups into a single array
  return Object.values(permissionGroups)
    .reduce((acc, group) => {
      return [...acc, ...Object.values(group.permissions)];
    }, [] as Permission[])
    .find((permission: Permission) => permission.key === permissionKey);
};

// "residents.view" -> t<Permission[...]>
export const getPermissionFromDotNotatedPath = (
  dependencyPermissionKey: string
): Permission => {
  const [groupKey, permissionKey] = dependencyPermissionKey.split(".");
  return permissionGroups[groupKey].permissions[permissionKey];
};

// "resident_permission_view" -> t<PermissionGroup[...]>
export const getPermissionGroupFromPermissionKey = (
  permissionKey: string
): PermissionGroup | undefined =>
  Object.values(permissionGroups).find((group) =>
    Object.values(group.permissions).find(
      (permission: Permission) => permission.key === permissionKey
    )
  );

// "resident_permission_view" -> t<PermissionGroup>.[key]
export const getPermissionGroupKey = (
  permissionGroup: PermissionGroup,
  permissionKey: string
): string | undefined =>
  Object.keys(permissionGroup.permissions).find(
    (permissionGroupKey: string) =>
      permissionGroup.permissions[permissionGroupKey].key === permissionKey
  );

interface DependencyDictionary {
  primary: Record<string, string[]>;
  inverse: Record<string, string[]>;
  settings: Record<string, string[]>;
}

/**
 * Iterate through each "permissions", and for each "dependsOn" value, add it to a new tree structure.
 * the tree structure ends up containing the top list of dependencies and each of their treeing dependencies -- allowing you to
 * fetch all dependencies from a top level key to make decisions without traversing a multi-level tree every time.
 */
export const getPermissionDependencyDictionaries = (): DependencyDictionary => {
  let dictionaries: DependencyDictionary = {
    primary: {},
    inverse: {},
    settings: Object.values(RoleSettings).reduce((acc, setting) => {
      acc[setting] = [];
      return acc;
    }, {} as { [key: string]: string[] }),
  };

  for (const group in permissionGroups) {
    for (const permission in permissionGroups[group].permissions) {
      const dependsOn =
        permissionGroups[group].permissions[permission].dependsOn;
      const requiresSettings =
        permissionGroups[group].permissions[permission].requireSettings;

      if (dependsOn) {
        const dictionaryKey = getPermissionDotNotatedPath(
          permissionGroups[group],
          permission
        );
        if (!dictionaries.primary[dictionaryKey]) {
          dictionaries.primary[dictionaryKey] = [];
        }

        dictionaries.primary[dictionaryKey].push(...dependsOn);

        dictionaries = getDependencies(dictionaries, dictionaryKey);
      }

      if (requiresSettings) {
        const dictionaryKey = getPermissionDotNotatedPath(
          permissionGroups[group],
          permission
        );
        requiresSettings.forEach((setting) => {
          dictionaries.settings[setting].push(dictionaryKey);
        });
      }
    }
  }

  return dictionaries;
};
const getDependencies = (
  dictionaries: DependencyDictionary,
  parentKey: string,
  subKey?: string
): DependencyDictionary => {
  const permission = getPermissionFromDotNotatedPath(subKey || parentKey);
  const dependsOn = permission?.dependsOn?.map((dependencyKey) => ({
    ...getPermissionFromDotNotatedPath(dependencyKey),
    // add own dependency key to the object to reference later
    dotPath: dependencyKey,
  }));

  const updatedDictionaries = { ...dictionaries };

  if (!updatedDictionaries.primary[parentKey]) {
    updatedDictionaries.primary[parentKey] = [];
  }

  const hasAllDependencies = dependsOn?.every((dependency) =>
    dependency.dependsOn
      ?.filter((childDependencyKey) => childDependencyKey !== parentKey)
      .every((childDependencyKey) =>
        updatedDictionaries.primary[parentKey]?.includes(childDependencyKey)
      )
  );

  if (dependsOn && !hasAllDependencies) {
    dependsOn.forEach((dependency) => {
      if (!updatedDictionaries.inverse[dependency.dotPath]) {
        updatedDictionaries.inverse[dependency.dotPath] = [];
      }

      if (
        !updatedDictionaries.inverse[dependency.dotPath]?.includes(parentKey) &&
        dependency.dotPath !== parentKey
      ) {
        updatedDictionaries.inverse[dependency.dotPath]?.push(parentKey);
      }

      dependency.dependsOn?.forEach((childDependencyKey) => {
        if (
          !updatedDictionaries.primary[parentKey]?.includes(
            childDependencyKey
          ) &&
          childDependencyKey !== parentKey
        ) {
          updatedDictionaries.primary[parentKey]?.push(childDependencyKey);
        }

        if (!updatedDictionaries.inverse[parentKey]) {
          updatedDictionaries.inverse[parentKey] = [];
        }

        if (
          !updatedDictionaries.inverse[parentKey]?.includes(
            childDependencyKey
          ) &&
          childDependencyKey !== parentKey
        ) {
          updatedDictionaries.inverse[parentKey]?.push(childDependencyKey);
        }

        if (
          !updatedDictionaries.inverse[dependency.dotPath]?.includes(
            childDependencyKey
          ) &&
          dependency.dotPath !== childDependencyKey
        ) {
          updatedDictionaries.inverse[dependency.dotPath]?.push(
            childDependencyKey
          );
        }
      });

      const result = getDependencies(
        updatedDictionaries,
        parentKey,
        dependency.dotPath
      );
      updatedDictionaries.primary = result.primary;
      updatedDictionaries.inverse = result.inverse;
    });
  }

  return updatedDictionaries;
};

export const permissionDependencyDictionaries =
  getPermissionDependencyDictionaries();
